<template>
  <div>
    <div class="container">
      <div class="row align-items-start">
        <div class="col-lg-4 me-auto mt-lg-0 mt-4">
          <button  v-on:click="add">Ajouter</button>
          <button  v-on:click="list">Lister</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
    }
  },
  mounted: function () {
  },
  watch: {},
  computed: {},
  methods: {
  }
}
</script>
